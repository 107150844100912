import React from 'react'
import { ReactComponent as CylanaLogo } from "../../../assets/svg/cylanaSvg.svg";

const CylanaLoading = ({logoSize, className, style}) => {

    const styles = {
        minWidth: logoSize || '125px',
        minHeight: logoSize || '125px',
        maxWidth: logoSize || 'auto',
        maxHeight: logoSize || 'auto'
    }

    return (
        <div className={`cylana-svg-loading-animation ${className}`} style={style}>
            <CylanaLogo style={styles}/>
        </div>
    )
}
export default CylanaLoading
