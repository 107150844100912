import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import osintService from 'services/osintService';
import {reject} from "lodash/collection";

const initialState = {
    osintData: null,
    domainSummary: '',
    domainStatus: '',
    osintDescription:null,
    domainEventResult:'',
    osintLoading: false,
    statusLoading: false,
    canRefresh: true,
    detailCurrentPage: 1
};

export const browseDomain = createAsyncThunk("osint/browseDomain", async ( data, {
    rejectWithValue
}) => {
    try {
        return await osintService.browseDomain(data);
    } catch (error) {
        return rejectWithValue(error);
    }
})

export const startOsintScan = createAsyncThunk(
    "osint/startOsintScan",
    async (domainId, {rejectWithValue}) => {
        try {
            const response = await osintService.startOsintScan(domainId);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getAllOsintResults = createAsyncThunk(
    "osint/getAllOsintResults",
    async (data, {rejectWithValue}) => {
        try {
            const response = await osintService.getAllOsintResults(
                data?.params, data?.data
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

export const getStatus = createAsyncThunk("osint/getStatus", async ( data, {
    rejectWithValue
}) => {
    try {
        return await osintService.getStatus(data);
    } catch (error) {
        return rejectWithValue(error);
    }
})

export const getDescription = createAsyncThunk("osint/getDescription", async ( data , {
    rejectWithValue
}) => {
    try {
        return await osintService.getDescription();
    } catch (error) {
        return rejectWithValue(error);
    }
})

export const getEventResults = createAsyncThunk("osint/getEventResults", async ( data, {
    rejectWithValue
}) => {
    try {
        return await osintService.getEventResults(data);
    } catch (error) {
        return rejectWithValue(error);
    }
})

export const osintSlice = createSlice({
    name: 'osintSlice',
    initialState,
    reducers: {
        setCanRefresh: (state, action) => {
            state.canRefresh = action.payload;
        },
        setDetailCurrentPage: (state, action) => {
            state.detailCurrentPage = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(browseDomain.fulfilled, (state, action) => {
            state.domainSummary = action.payload.data;
            state.osintLoading = false;
        });
        builder.addCase(browseDomain.pending, (state, action) => {
            state.osintLoading = true;
            state.domainSummary = '';
        });
        builder.addCase(browseDomain.rejected, (state, action) => {
            state.osintLoading = false;
        });
        builder.addCase(getStatus.fulfilled, (state, action) => {
            state.domainStatus = action.payload.data;
            state.statusLoading = false;
        });
        builder.addCase(getStatus.pending, (state, action) => {
            state.statusLoading = true;
            state.domainStatus = '';
        });
        builder.addCase(getStatus.rejected, (state, action) => {
            state.statusLoading = false;
        });
        builder.addCase(getEventResults.fulfilled, (state, action) => {
            state.domainEventResult = action.payload.data
            state.osintLoading = false;
        });
        builder.addCase(getEventResults.pending, (state, action) => {
            state.osintLoading = true;
            state.domainEventResult = '';
        });
        builder.addCase(getEventResults.rejected, (state, action) => {
            state.osintLoading = false;
        });
        builder.addCase(getAllOsintResults.fulfilled, (state, action) => {
            state.osintData = action.payload;
            state.osintLoading = false;
        });
        builder.addCase(getAllOsintResults.pending, (state, action) => {
            state.osintLoading = true;
        });
        builder.addCase(getAllOsintResults.rejected, (state, action) => {
            state.osintLoading = false;
        });
        builder.addCase(getDescription.pending, (state, action) => {
            state.osintDescription = null;
        });
        builder.addCase(getDescription.fulfilled, (state, action) => {
            state.osintDescription = action.payload.data;
        });
        builder.addCase(getDescription.rejected, (state, action) => {
            console.log('Get Description Rejected!');
        })
    }
});

export const {
    setCanRefresh,
    setDetailCurrentPage
} = osintSlice.actions;

export default osintSlice.reducer;
