import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import newsService from "../../services/newsService";

export const getNews = createAsyncThunk(
  "news/getNews",
  async ({ title, fetchDate }, { rejectWithValue }) => {
    try {
      const response = await newsService.getNews(title, fetchDate);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getNewsFetchDates = createAsyncThunk(
  "news/getNewsFetchDates",
  async (_, { rejectWithValue }) => {
    try {
      const response = await newsService.getNewsFetchDates();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchByDomain = createAsyncThunk(
  "news/fetchByDomain",
  async (domainId, { rejectWithValue }) => {
    try {
      const response = await newsService.fetchByDomain(domainId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDomainNews = createAsyncThunk(
  "news/getDomainNews",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await newsService.getDomainNews();
      return response?.data
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDomainNewsDetails = createAsyncThunk(
  "news/getDomainNewsDetails",
  async ( data , { rejectWithValue }) => {
    try {
      const response = await newsService.getDomainNewsDetails(data?.data, data?.params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  news: [],
  fetchDates: [],
  newsLoading: false,
  domainNews: [],
  domainNewsLoading: true,
  domainNewsDetails: null,
  domainNewsDetailsLoading: false,
};

export const newsSlice = createSlice({
  name: "newsSlice",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getNews.fulfilled, (state, action) => {
      state.news = action.payload;
      state.newsLoading = false;
    });

    builder.addCase(getNews.pending, (state) => {
      state.newsLoading = true;
    });

    builder.addCase(getNews.rejected, (state) => {
      state.newsLoading = false;
    });

    builder.addCase(getNewsFetchDates.fulfilled, (state, action) => {
      state.fetchDates = action.payload;
    });

    builder.addCase(getDomainNewsDetails.fulfilled, (state, action) => {
      state.domainNewsDetails = action.payload;
    });

    builder.addCase(getDomainNewsDetails.rejected, (state) => {
      state.domainNewsDetails = [];
    });

    builder.addCase(getDomainNewsDetails.pending, (state) => {
      state.domainNewsDetails = [];
      state.domainNewsDetailsLoading = true;
    });

    builder.addCase(getDomainNews.fulfilled, (state, action) => {
      state.domainNews = action.payload;
      state.domainNewsLoading = false;
    });

    builder.addCase(getDomainNews.pending, (state) => {
      state.domainNews = [];
      state.domainNewsLoading = true;
    });

    builder.addCase(getDomainNews.rejected, (state, action) => {
      state.domainNews = [];
      state.domainNewsLoading = false;
    });
  },
});

export const {
} = newsSlice.actions;

export default newsSlice.reducer;
