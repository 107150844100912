import React from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import CylanaLoading from "../../../v2/components/general/CylanaLoading";

const Icon = <LoadingOutlined style={{ fontSize: 100 }} spin />;

const Loading = (props) => {
	const { align, cover } = props;
	return (
		<div className={`loading text-${align} cover-${cover}`}>
			<CylanaLoading/>
		</div>
	);
};

Loading.propTypes = {
	size: PropTypes.string,
	cover: PropTypes.string,
};

Loading.defaultProps = {
	align: 'center',
	cover: 'inline',
};

export default Loading;
