import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import domainService from "../../services/domain.service";
import {downloadFile} from "../../utils/FileUtil";

export const createDomain = createAsyncThunk("domain/createDomain", async (data, {
    rejectWithValue
}) => {
    try {
        return await domainService.createDomain(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const createIps = createAsyncThunk("domain/createIps", async (data, {
    rejectWithValue
}) => {
    try {
        return await domainService.createIps(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getCriticalLevelsByDomain = createAsyncThunk("domain/getCriticalLevelsByDomain", async (data, {
    rejectWithValue
}) => {
    try {
        return await domainService.getCriticalLevelsByDomain(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getDomainsFiltered = createAsyncThunk("domain/getDomainFiltered", async (data, {
    dispatch,
    rejectWithValue
}) => {
    try {
        const response = await domainService.getDomainsFiltered(data?.params, data?.data);
        dispatch(setFilterRequest(data));
        dispatch(setDomainCreatedDateFilter(data?.data?.createdDate));
        return response;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const deleteDomain = createAsyncThunk("domain/deleteDomain", async (data, {
    rejectWithValue
}) => {
    try {
        return await domainService.deleteDomain(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const downloadFileDomain = createAsyncThunk("domain/downloadFileDomain", async (data, {
    rejectWithValue
}) => {
    try {
        return await domainService.downloadFileDomain(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const triggerDomainScan = createAsyncThunk("domain/triggerDomainScan", async (data, {
    rejectWithValue
}) => {
    try {
        return await domainService.triggerDomainScan(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getDomainCount = createAsyncThunk("domain/getDomainCount", async (data, {
    rejectWithValue
}) => {
    try {
        return await domainService.getDomainCount();
    } catch (eror) {
        return rejectWithValue();
    }
});

export const getSubDomainCount = createAsyncThunk("domain/getSubDomainCount", async (data, {
    rejectWithValue
}) => {
    try {
        return await domainService.getSubDomainCount();
    } catch (eror) {
        return rejectWithValue();
    }
});

export const getIpCount = createAsyncThunk("domain/getIpCount", async (data, {
    rejectWithValue
}) => {
    try {
        return await domainService.getIpCount();
    } catch (eror) {
        return rejectWithValue();
    }
});

export const getDetailSubDomains = createAsyncThunk("domain/getDetailSubDomains", async (data, {
    rejectWithValue
}) => {
    try {
        return await domainService.getDetailSubDomains(data?.data, data?.params);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getDomainIssues = createAsyncThunk("domain/getDomainIssues", async (data, {
    rejectWithValue
}) => {
    try {
        return await domainService.getDomainIssues(data?.data, data?.params);
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const getAllDomains = createAsyncThunk("domain/getAllDomains", async (data, {
    rejectWithValue
}) => {
    try {
        return await domainService.getAllDomains(data);
    } catch (error) {
        return rejectWithValue(error);
    }
});

const initialState = {
    domains: null,
    allDomains: [],
    getAllDomainLoading:false,
    detailSubDomains: [],
    domainIssues: [],
    singleIssueDetail: null,
    numberOfElements: 0,
    assetCount:{
        domain:0,
        subDomain:0,
        ip:0
    },
    criticalLevelsByDomain:null,
    currentStep: 0,
    scanAssetLoading: false,
    refreshTable: false,
    deleteLoading: false,
    detailSubdomainsLoading: false,
    domainIssuesLoading: false,
    pdfLoading: false,
    domainTab: 'DOMAIN',
    formType: 'domain',
    defaultPageSize: 10,
    domainScanLoading: false,
    domainScanError: null,
    createStatus: {
        message: null,
        error: false,

    },

    isFilter: false,

    tableProperty: {
        current: 1, page: 0, size: 20, total: 0
    },

    createdDateFilter: null,

    fullRequest: {
      data: {
        name:"",
        ip:"",
        type:"DOMAIN",
        createdDate: null
      },

      params: {

      }
    },

    filterRequest: {},

    getAllFilterRequest: {
        data: {
            name: "",
            ip: "",
            type: "DOMAIN",
            createdDate: null
        },

        params: {
            page: 0,
            size: 100
        }
    },

    defaultFilterRequest: {
        data: {
            name: "",
            ip: "",
            type: "DOMAIN",
            createdDate: null
        },

        params: {
            page: 0,
            size: 6
        }
    },
};

export const domainSlice = createSlice({
    name: 'domainSlice',
    initialState,
    reducers: {
        setDomainCreatedDateFilter: (state, action) => {
            state.createdDateFilter = action.payload;
        },

        setFilterRequest: (state, action) => {
            state.filterRequest = action.payload;
        },

        setDomainTab: (state, action) => {
            state.domainTab = action.payload;
        },

        setStep: (state, action) => {
            state.currentStep = action.payload;
        },

        setIsFilter: (state, action) => {
            state.isFilter = action.payload;
        },

        setTableProperty: (state, action) => {
            state.tableProperty = action.payload;
        },
        setFormType: (state, action) => {
            state.formType = action.payload;
        },
        setRefreshTable: (state, action) => {
            state.refreshTable = action.payload;
        },
        setSingleIssueDetail: (state, action) => {
            state.singleIssueDetail = action.payload;
        },
        setDomainScanError: (state, action) => {
            state.domainScanError = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createDomain.fulfilled, (state, action) => {
            console.log(action);
            if(action?.payload?.message){
                state.currentStep = 2;
                state.createStatus = {
                    message: action?.payload?.response?.data?.error,
                    error: true,
                }
            } else {
                state.currentStep = 2;
            }
            state.scanAssetLoading = false;
        });
        builder.addCase(createDomain.rejected, (state, action) => {
            state.currentStep = 2;
            state.createStatus = {
                message: action.payload?.response?.data?.error,
                error: true,
            }
            state.scanAssetLoading = false;
        });
        builder.addCase(createDomain.pending, (state) => {
            state.currentStep = 1;
            state.createStatus = {
                message: '',
                error: false,
            }
            state.scanAssetLoading = true;
        });

        builder.addCase(createIps.fulfilled, (state) => {
            state.currentStep = 2;
            state.scanAssetLoading = false;
        });
        builder.addCase(createIps.rejected, (state, action) => {
            state.currentStep = 2;
            state.createStatus = {
                message: action.payload?.response?.data?.error,
                error: true,
            }
            state.scanAssetLoading = false;
        });
        builder.addCase(createIps.pending, (state) => {
            state.currentStep = 2;
            state.scanAssetLoading = true;
        });

        builder.addCase(getDomainsFiltered.fulfilled, (state, action) => {
            state.domains = action?.payload?.data;
            state.numberOfElements= action?.payload?.data?.numberOfElements;
            state.tableProperty = {
                total: action?.payload?.data?.totalElements,
                current: action?.payload?.data?.number + 1,
                page: action?.payload?.data?.number,
                size: action?.payload?.data?.size
            };

            state.tableProperty.total = action?.payload?.data?.totalElements;
            state.tableProperty.page = action?.payload?.data?.number;
            state.tableProperty.size = action?.payload?.data?.size;
            state.tableProperty.current = action?.payload?.data?.number + 1;

            state.tableLoading = false;
        });

        builder.addCase(getDomainsFiltered.pending, (state, action) => {
            state.tableLoading = true;
        });

        builder.addCase(getDomainsFiltered.rejected, (state, action) => {
            state.tableLoading = false;
        });

        builder.addCase(downloadFileDomain.pending, (state, action) => {
            state.pdfLoading = true;
        });

        builder.addCase(downloadFileDomain.rejected, (state, action) => {
            state.pdfLoading = false;
        });

        builder.addCase(downloadFileDomain.fulfilled, (state, action) => {
            state.pdfLoading = false;
            downloadFile({
                data: action.payload.data,
                fileName: action.meta?.arg?.name
            });
        });

        builder.addCase(deleteDomain.pending, (state, action) => {
            state.tableLoading = true;
        });

        builder.addCase(deleteDomain.rejected, (state, action) => {
            state.tableLoading = false;
        });

        builder.addCase(deleteDomain.fulfilled, (state, action) => {
            state.tableLoading = false;
            state.refreshTable = true;
        });

        builder.addCase(getDomainCount.fulfilled, (state, action) => {
            state.assetCount = {...state.assetCount,domain:action.payload.data};
        });

        builder.addCase(getSubDomainCount.fulfilled, (state, action) => {
            state.assetCount = {...state.assetCount,subDomain:action.payload.data};
        });

        builder.addCase(getIpCount.fulfilled, (state, action) => {
            state.assetCount = {...state.assetCount,ip:action.payload.data};
        });
        builder.addCase(getCriticalLevelsByDomain.pending, (state,action) => {
            state.criticalLevelsByDomain = null;
        });
        builder.addCase(getCriticalLevelsByDomain.fulfilled, (state,action) => {
            state.criticalLevelsByDomain = action.payload.data;
        });
        builder.addCase(getDetailSubDomains.fulfilled, (state,action) => {
            state.detailSubDomains = action.payload.data;
            state.detailSubdomainsLoading = false;
        });
        builder.addCase(getDetailSubDomains.rejected, (state,action) => {
            state.detailSubdomainsLoading = false;
        });
        builder.addCase(getDetailSubDomains.pending, (state,action) => {
            state.detailSubdomainsLoading = true;
            state.detailSubDomains = [];
        });
        builder.addCase(getDomainIssues.pending, (state,action) => {
            state.domainIssuesLoading = true;
            state.domainIssues = [];
        });
        builder.addCase(getDomainIssues.rejected, (state,action) => {
            state.domainIssuesLoading = false;
        });
        builder.addCase(getDomainIssues.fulfilled, (state,action) => {
            state.domainIssuesLoading = false;
            state.domainIssues = action.payload.data;
        });
        builder.addCase(triggerDomainScan.pending, (state,action) => {
            state.domainScanLoading = true;
            state.domainScanError = null;
        });
        builder.addCase(triggerDomainScan.rejected, (state,action) => {
            state.domainScanLoading = false;
            state.domainScanError = 'error';
        });
        builder.addCase(triggerDomainScan.fulfilled, (state,action) => {
            state.domainScanLoading = false;
            if (action?.payload?.response?.status == 429) {
                const decodedResponse = new TextDecoder().decode(action?.payload?.response?.data);
                const responseString = JSON.parse(decodedResponse);
            
                const match = responseString?.error?.match(/Try Again In (\d+) Minutes/);
            
                if (match) {
                    const minutes = parseInt(match[1], 10);
                    state.domainScanError = minutes;
                }
            }
            else if(action.payload?.response?.status) {
                state.domainScanError = 'error'
            } else {
                state.domainScanError = 'success';
            }
        });
        builder.addCase(getAllDomains.fulfilled, (state,action) => {
            state.allDomains = action?.payload;
            state.getAllDomainLoading = false;
        });
        builder.addCase(getAllDomains.pending, (state,action) => {
            state.getAllDomainLoading = true;
        });
        builder.addCase(getAllDomains.rejected, (state,action) => {
            state.getAllDomainLoading = false;
        });
    }
});

export const {
    setStep,
    setIsFilter,
    setFilterRequest,
    setDomainTab,
    setFormType,
    setRefreshTable,
    setSingleIssueDetail,
    setDomainCreatedDateFilter,
    setDomainScanError
} = domainSlice.actions;

export default domainSlice.reducer;

